import LazyLoad from 'lazyload/lazyload';

let images = document.querySelectorAll('img.lazyload');
new LazyLoad(images);

window.LazyLoad = LazyLoad;

$(window).on('load', function() {
  // загрузка изображений методом LazyLoad
	setTimeout(() => {
		let img = document.querySelectorAll("img.load");
		let lazy = new LazyLoad(img);
		lazy.loadImages();
	}, 1000);
});